<template>
  <v-card>
    <v-card-text>
        <v-row class="py-4">
          <v-col cols="12" class="text-center text-heading-4">
            訪問メモ登録
            <div>
              <v-btn
                depressed
                class="close-button"
                fab
                color="#E27C7C"
                width="20"
                height="20"
                @click="$emit('close-dialog')"
              >
                <v-icon size="14">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <validation-observer ref="observer">
          <form @submit.prevent="save">
            <v-col cols="12">
              <div class="form-header mb-2">訪問日</div>
              <div>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                    v-slot="{ errors }"
                    name="訪問日"
                    rules="required"
                    >
                      <v-text-field
                        v-model="data.visited_date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        hide-details
                        outlined
                        placeholder="訪問日"
                        :error-messages="errors"
                        :error="errors.length !== 0"
                      ></v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-model="data.visited_date"
                    @input="menu = false"
                    :day-format="formatDate"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="form-header mb-2">訪問者</div>
              <div>
                <validation-provider v-slot="{ errors }" name="訪問者" rules="required">
                  <v-select
                    class="form-text"
                    outlined
                    dense
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    :items="visitedUsers"
                    item-text="full_name"
                    hide-details="auto"
                    placeholder="訪問者"
                    chips
                    multiple
                    clearable
                    v-model="data.user_id"
                  >
                    <template v-slot:selection="{ attrs, item, select }">
                      <v-chip
                        class="mb-1"
                        small
                        close
                        v-bind="attrs"
                        @click="select"
                        @click:close="removeTag(item.value)"
                      >
                        <span>{{ item.text }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </validation-provider>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="form-header mb-2">訪問メモ</div>
              <div>
                <validation-provider v-slot="{ errors }" name="訪問メモ" rules="required">
                  <v-textarea
                    class="form-text"
                    dense
                    outlined
                    placeholder="訪問メモ"
                    hide-details
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    v-model="data.memo"
                    @keyup='desCount()' 
                    :maxlength="descriptionMaxLength"
                  ></v-textarea>
                </validation-provider>
                <div class="text-right">
                  {{ totalDescharacter?totalDescharacter: data.memo.length }} / {{ descriptionMaxLength }} 文字
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <v-row align="center">
                <v-col class="text-right" cols="auto" v-if="this.visitMemoItem && this.visitMemoItem.id">
                  <v-btn @click="deleteTask" color="#AD4545" text><v-icon left> mdi-delete </v-icon> 削除する</v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn @click="save" color="#4F55A7" class="white--text" width="100">更新</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </form>
        </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters,mapMutations } from "vuex";
import dayjs from '@/plugins/dayjs'
export default {
  name: 'Dialog',
  props: {
    customerId: {
      type: String,
      default: '',
      required: true
    },
    visitMemoItem: {
      type: Object,
      default: () => {},
      required: false
    },
    customer: {
      type: Object,
      required: true
    },
    visitedUsers: {
      type: Array,
      required: true
    },
  },
  computed: {
    ...mapGetters(["user"])
  },
  watch: {
    visitMemoItem: function() {
      this.data.memo = ''
      this.data.user_id = []
      this.data.visited_date = null
      this.patchEditFormValues()
    }
  },
  created() {
    this.patchEditFormValues()
  },
  methods: {
    ...mapMutations(['confirmationModal']),
    patchEditFormValues() {
      if (this.visitMemoItem && this.visitMemoItem.id) {
        this.data.memo = this.visitMemoItem.memo
        this.data.user_id = JSON.parse(this.visitMemoItem.user_id)
        this.data.customer_id = this.customerId,
        this.data.visited_date = this.visitMemoItem.visited_date
      } else {
        this.data.memo = ''
        this.data.user_id = []
        this.data.visited_date = null
        this.data.customer_id = this.customerId
      }
    },
    save() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        if (this.visitMemoItem && this.visitMemoItem.id) {
          let data = {}
          data.visit_id = this.visitMemoItem.id
          data.memo = this.data.memo
          data.user_id = this.data.user_id
          data.customer_id = this.customerId
          data.visited_date = this.data.visited_date

          this.$store.dispatch('VISIT_MEMO_EDIT', data).then(
            response => {
              if (response.data.status == 'success') {
                  this.$router.go(this.$router.currentRoute)
                  this.$emit('close-dialog')
              }
            },
            error => {
              throw error
            }
          )
        } else {
          this.$store.dispatch('VISIT_MEMO_CREATE', this.data).then(
            response => {
              if (response.data.status == 'success') {
                  this.$router.go(this.$router.currentRoute)
                  this.$emit('close-dialog')
              }
            },
            error => {
              throw error
            }
          )
        }
      })
    },
    deleteTask() {
      this.confirmationModal({
        text: 'このアクションを実施してよろしいですか',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.processDelete
      })
    },
    processDelete() {
      if (this.visitMemoItem && this.visitMemoItem.id) {
        let visit_id = this.visitMemoItem.id
        this.$store.dispatch('VISIT_MEMO_DELETE', {visit_id }).then(
          response => {
            if (response.data.status == 'success') {
                this.$router.go(this.$router.currentRoute)
                this.$emit('close-dialog')
            }
          },
          error => {
            throw error
          }
        )
      }
    },
    desCount: function(){
      this.totalDescharacter = this.data.memo.length;
    },
    removeTag(item) {
      this.data.user_id = this.data.user_id.filter((visitedUsers) => visitedUsers !== item);
    },
    formatDate(e) {
      return dayjs(e).format('D')
    }
  },
  data() {
    return {
      descriptionMaxLength: 500,
      data: {
        memo:"",
        user_id:[],
        customer_id: "",
        visit_id: "",
        visited_date: null
      },
      menu: false,
      totalDescharacter:0
    }
  }
}
</script>

<style lang="scss" src="./Dialog.scss" scoped></style>
