<template>
  <div class="visit-memo-box pa-5">
    <v-dialog ref="addVisitMemoDialog" v-model="addVisitMemoDialog" width="500">
      <AddVisitMemoDialog
        :visit-memo-item="visitMemo"
        :customer-id="customerId"
        :customer="customer"
        :visitedUsers="visitedUsers"
        @close-dialog="addVisitMemoDialog = false"
      ></AddVisitMemoDialog>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="visit-memo-box-headline">
        <v-row align="center">
          <v-col cols="auto">訪問メモ</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="flex-grow-1">
        <v-row v-for="item in memos" :key="item.id" align="center">
          <v-col cols="12" class="visit-memo-box-date text-heading-4">
            <v-row>
              <v-col cols="10">
                <span class="mr-3">{{ item.visited_date }}</span>
              </v-col>
              <v-col cols="2" class="text-right">
                <v-btn fab x-small color="editColor" class="white--text" @click="editVisitMemo(item)">
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="3" class="visit-memo-box-title text-heading-4">訪問者</v-col>
              <v-col cols="9" class="text-heading-3">{{ visitUsers(item) }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="visit-memo-box-title py-0 text-heading-4">
            訪問メモ
          </v-col>
          <v-col cols="12" class="visit-memo-box-content pt-3 text-heading-3">
            {{ item.memo }}
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AddVisitMemoDialog from '@/components/layout/customer/VisitMemo/Dialog'
import dayjs from "@/plugins/dayjs";
export default {
  name: "VisitMemos",
  components: {AddVisitMemoDialog},
  props: ["customer", "sites", "visitMemos","visitedUsers","customerId"],
  methods: {
    visitUsers(item) {
      let visitedUserTxt = ''
      if (item.user_id && item.user_id != "null") {
        this.visitedUsers.map(function(value) {
          if (JSON.parse(item.user_id).includes(value.value)) {
            if (visitedUserTxt == '') {
              visitedUserTxt = value.text
            } else {
              visitedUserTxt = visitedUserTxt + ', ' + value.text
            }
          }
        })
      }
      return visitedUserTxt
    },
    editVisitMemo: function (item) {
      this.addVisitMemoDialog = true
      this.visitMemo = item
    },
  },
  data() {
    return {
      addVisitMemoDialog: false,
      visitMemo: {}
    };
  },
  computed: {
    memos() {
      return this.visitMemos.filter((a) => a).sort((a,b) => {
        let dateA = dayjs(a.visited_date)
        let dateB = dayjs(b.visited_date)
        return dateA.isBefore(dateB) ? 1 : -1
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.visit-memo-box {
  background: #F7F8FF;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  &-headline {
    font-size: 21px;
    color: #5F63AB;
    font-weight: 500;
  }

  font-size: 16px;

  &-date {
    color: #878787;
  }

  &-title {
    color: #878787;
  }

  &-content {
    color: #333333;
  }
}
.visit-memo-box-content{white-space: break-spaces;text-align: justify;}
</style>
