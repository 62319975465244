<template>
  <div>
    <v-dialog ref="addVisitMemoDialog" v-model="addVisitMemoDialog" width="500">
      <AddVisitMemoDialog
        :visit-memo-item="visitMemo"
        :customer-id="customer_id"
        :customer="customer"
        :visitedUsers="visitedUsers"
        @close-dialog="addVisitMemoDialog = false"
      ></AddVisitMemoDialog>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="flex-grow-1 text-right">
        <v-btn height="28" class="btn-new-task" color="#CFD3FE" depressed min-width="100" @click="addVisitMemo">
          <v-icon left> mdi-plus </v-icon>  訪問メモ 
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <VisitMemos :customer="customer" :visitMemos="visitMemos" :visitedUsers="visitedUsers" :customer-id="customer_id" />
      </v-col>
    </v-row>
    </div>
</template>

<script>
import VisitMemos from "@/components/layout/customer/VisitMemos.vue";
import AddVisitMemoDialog from '@/components/layout/customer/VisitMemo/Dialog'
export default {
  name: "VisitMemo",
  props: {
    customer_id:{
      type: [String,Number],
      required: true
    }
  },
  components: { VisitMemos,AddVisitMemoDialog },
  computed: {
    customer() {
      return this.$store.getters.getCustomer;
    },
    visitMemos() {
      return this.$store.getters.allVisitMemos;
    },
    visitedUsers() {
      return this.$store.getters.allUsers.map(visitedUsers => {
        return { value: visitedUsers.id, text: visitedUsers.last_name, full_name:visitedUsers.full_name }
      })
    }
  },
  created() {
    this.getDataFromApi()
    this.updateCustomerVisitMemos()
  },
  data() {
    return {
      addVisitMemoDialog: false,
      visitMemo: {},
      data: {
        site: 0
      }
    };
  },
  methods:{
    addVisitMemo() {
      this.addVisitMemoDialog = true
      this.visitMemo = {}
    },
    getDataFromApi() {
      this.$store.dispatch('USER_GET_ALL').then(() => {
        this.loading = false
      })
    },
    updateCustomerVisitMemos() {
      let param = {
        customer_id: this.$route.params?.customer_id
      }
      this.$store.dispatch('VISIT_MEMO_GET_ALL', param).then(() => {
        this.loading = false
      })
    }
  }
};
</script>
